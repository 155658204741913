var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "modal-remove-group-" + _vm.groupIndex,
        attrs: {
          id: "modal-remove-group-" + _vm.groupIndex,
          "footer-class": "flex-nowrap",
          "no-close-on-backdrop": "",
          centered: ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function() {
              return [
                _c("h3", [_vm._v(_vm._s(_vm.$t("modals.removeGroup.title")))])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "delete-icon text-center px-2" },
                  [
                    _c("b-icon", {
                      staticClass: "mt-5 mb-4",
                      attrs: {
                        icon: "exclamation-triangle-fill",
                        variant: "danger"
                      }
                    }),
                    _c("div", {
                      staticClass: "mt-2 mb-4",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("modals.removeGroup.message_one")
                        )
                      }
                    }),
                    _vm.groupChildren && _vm.groupChildren.length !== 0
                      ? _c("div", {
                          staticClass: "mt-2 mb-2",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("modals.removeGroup.message_two")
                            )
                          }
                        })
                      : _vm._e(),
                    _vm.groupChildren && _vm.groupChildren.length !== 0
                      ? _c("SubGroupName", {
                          attrs: { groupChildren: _vm.groupChildren }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-4 mx-5 mb-4" },
                  [
                    _c("div", {
                      staticClass: "mb-2",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("modals.removeGroup.message_three", {
                            name: _vm.groupName
                          })
                        )
                      }
                    }),
                    _c("b-form-input", {
                      attrs: { autofocus: "", debounce: "500" },
                      model: {
                        value: _vm.typedName,
                        callback: function($$v) {
                          _vm.typedName = $$v
                        },
                        expression: "typedName"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "modal-footer",
            fn: function({ close }) {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "p-3 border-right-light-grey",
                    attrs: { block: "" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "p-3",
                    attrs: {
                      variant: "primary",
                      block: "",
                      disabled: _vm.typedName !== _vm.groupName
                    },
                    on: { click: _vm.removeGroup }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }