<template>
  <div>
    <b-modal
        :id="'modal-remove-group-' + groupIndex"
        :ref="'modal-remove-group-' + groupIndex"
        footer-class="flex-nowrap"
        no-close-on-backdrop
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.removeGroup.title') }}</h3>
      </template>

      <template #default>
        <div class="delete-icon text-center px-2">
          <b-icon icon="exclamation-triangle-fill" variant="danger" class="mt-5 mb-4"></b-icon>
          <div class="mt-2 mb-4" v-html="$t('modals.removeGroup.message_one')"></div>
          <div
              v-if="groupChildren && groupChildren.length !== 0"
              class="mt-2 mb-2"
              v-html="$t('modals.removeGroup.message_two')"
          >
          </div>
          <SubGroupName
            v-if="groupChildren && groupChildren.length !== 0"
            :groupChildren="groupChildren"
          >
          </SubGroupName>
        </div>
        <div class="mt-4 mx-5 mb-4">
          <div
              class="mb-2"
              v-html="$t('modals.removeGroup.message_three', { name: groupName })"
          >
          </div>
          <b-form-input
              v-model="typedName"
              autofocus
              debounce="500"
          >
          </b-form-input>
        </div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="close()"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            variant="primary"
            class="p-3"
            block
            :disabled="typedName !== groupName"
            @click="removeGroup"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalRemoveGroup',
  components: {
    SubGroupName: () => import('@/components/settings/groups/SubGroupName.vue'),
  },
  props: ['groupIndex', 'groupName', 'groupChildren'],
  data() {
    return {
      typedName: '',
    };
  },
  methods: {
    async removeGroup() {
      const modalRef = `modal-remove-group-${this.groupIndex}`;
      this.$refs[modalRef].hide();
      this.$emit('removeGroup', this.groupIndex, this.typedName);
    },
  },
};
</script>

<style scoped>

</style>
